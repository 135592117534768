<template>
    <div class="productionNum">
        <div class="clue-statistics-box-child">
            <div>
                <div class="select-time">
                    <el-date-picker
                        v-model="value1"
                        type="month"
                        @change="changeSelectTime"
                        value-format="yyyy-MM"
                        format="yyyy-MM"
                        placeholder="选择日期"
                    >
                    </el-date-picker>
                    <el-select
                        v-model="selectStation"
                        placeholder="请选择"
                        @change="changeStation"
                    >
                        <el-option
                            v-for="item in stationList"
                            :key="item.mixstation_code"
                            :label="item.mixstation_name"
                            :value="item.mixstation_code"
                        >
                        </el-option>
                    </el-select>
                </div>
                <div class="clue-statistics-one">
                    <div style="width:100%;height:280px;" id="clueStatisticsChildId"></div>
                </div>
                <!-- 导出excel -->
                <exportDom :dom-id="'#statisticsDom'" :excel-name="'按产品标号生产发货统计'"></exportDom>
                <div class="clue-statistics-two">
                    <el-table
                        id="statisticsDom"
                        :data="tableData"
                        border
                        height="100%"
                        style="width: 100%"
                        show-summary
                        :summary-method="getSummaries"
                    >
                        <!-- <el-table-column
                            label="企业"
                            width="180"
                        >
                            <template>
                                <div>
                                    {{ deptName }}
                                </div>
                            </template>
                        </el-table-column> -->
                        <el-table-column
                            prop="name"
                            label="强度等级"
                            width="180"
                        >
                        </el-table-column>
                        <el-table-column
                            prop="fhcc"
                            label="发货车次(次)"
                            width="180"
                        >
                        </el-table-column>
                        <el-table-column
                            prop="fhfl"
                            label="发货方量(方)"
                        >
                        </el-table-column>
                        <el-table-column
                            prop="syfl"
                            label="剩余方量(方)"
                        >
                        </el-table-column>
                        <el-table-column
                            prop="thfl"
                            label="退砼方量(方)"
                        >
                        </el-table-column>
                        <el-table-column
                            prop="qsfl"
                            label="签收方量(方)"
                        >
                        </el-table-column>
                        <el-table-column
                            prop="ykfl"
                            label="盈亏方量(方)"
                        >
                        </el-table-column>
                        <el-table-column
                            prop="qsl"
                            label="签收率"
                        >
                        </el-table-column>
                        <el-table-column
                            prop="thl"
                            label="退货率"
                        >
                        </el-table-column>
                    </el-table>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import exportDom from './../salesStatistics/components/export';
export default {
    components: {
        exportDom,
    },
    data() {
        return {
            value1: '',
            tableData: [],
            tableName: [],
            tableXsts: [],
            tableCgs: [],
            deptName: '',
            qslLineEcharts: [],
            // 场站
            selectStation: '',
            stationList: [],
        };
    },
    mounted() {
        this.$nextTick(() => {
            this.deptName = this.$takeTokenParameters('TenantName');
            this.value1 = this.timeFormat();
            this.salesanalysisYwy();
            this.getStation();
        });
    },
    methods: {
        changeSelectTime() {
            this.salesanalysisYwy();
        },
        timeFormat() {
            const date = new Date();
            const y = date.getFullYear(); // 年
            let m = date.getMonth() + 1; // 月
            let d = date.getDate(); // 日
            m = m > 9 ? m : '0' + m;
            d = d > 9 ? d : '0' + d;
            return `${y}-${m}`;
        },
        closeClueStatistics() {
            this.$emit('closeChildAlert');
        },
        // 获取场站列表
        async getStation() {
            const res = await this.getStationApi();
            if (res) {
                this.stationList = res;
            }
        },
        // 下拉选择场站
        changeStation() {
            this.salesanalysisYwy();
        },
        getSummaries(param) {
            const { columns, data } = param;
            const sums = [];
            columns.forEach((column, index) => {
                // if (index === 0) {
                //     sums[index] = ' ';
                //     return;
                // }
                if (index === 0) {
                    sums[index] = '合计';
                    return;
                }
                const values = data.map(item => Number(item[column.property]));
                if (!values.every(value => isNaN(value))) {
                    sums[index] = values.reduce((prev, curr) => {
                        const value = Number(curr);
                        if (!isNaN(value)) {
                            return prev + curr;
                        }
                        return prev;
                    }, 0);

                    if (column.label === '签收率' || column.label === '退货率') {
                        let thTotal = 0;
                        let qsTotal = 0;
                        let fhTalXl = 0;
                        this.tableData.forEach(item => {
                            thTotal += Math.round(item.thfl * 100);
                            qsTotal += Math.round(item.qsfl * 100);
                            fhTalXl += Math.round(item.fhfl * 100);
                        });
                        if (column.label === '签收率') {
                            sums[index] = Number(fhTalXl) ? (qsTotal / fhTalXl * 100).toFixed(2) + '%' : '0.00';
                        } else if (column.label === '退货率') {
                            sums[index] = Number(fhTalXl) ? (thTotal / fhTalXl * 100).toFixed(2) + '%' : '0.00';
                        }
                    }
                } else {
                    sums[index] = ' ';
                    if (column.label === '签收率' || column.label === '退货率') {
                        let thTotal = 0;
                        let qsTotal = 0;
                        let fhTalXl = 0;
                        this.tableData.forEach(item => {
                            thTotal += Math.round(item.thfl * 100);
                            qsTotal += Math.round(item.qsfl * 100);
                            fhTalXl += Math.round(item.fhfl * 100);
                        });
                        if (column.label === '签收率') {
                            sums[index] = Number(fhTalXl) ? (qsTotal / fhTalXl * 100).toFixed(2) + '%' : '0.00';
                        } else if (column.label === '退货率') {
                            sums[index] = Number(fhTalXl) ? (thTotal / fhTalXl * 100).toFixed(2) + '%' : '0.00';
                        }
                    }
                }
            });

            return sums;
        },
        objectSpanMethod({ rowIndex, columnIndex }) {
            if (columnIndex === 0) {
                if (rowIndex === 0) {
                    return {
                        rowspan: 1000,
                        colspan: 1,
                    };
                }
                return {
                    rowspan: 0,
                    colspan: 0,
                };

            }
        },
        clueStatisticsChildFun() {
            const myChart = this.$echarts.init(document.getElementById('clueStatisticsChildId'));
            const option = {
                grid: {
                    top: '25%',
                    left: '4%',
                    right: '4%',
                    bottom: '3%',
                    containLabel: true,
                },
                legend: {
                    itemWidth: 8,
                    itemHeight: 8,
                    data: ['发货方量', '签收方量', '签收率'],
                    x: 'right',
                    padding: [20, 50, 0, 0],
                },
                xAxis: {
                    data: this.tableName,
                    type: 'category',
                    axisLabel: {
                        color: '#666666',
                        fontSize: 10,
                    },
                    axisTick: {
                        show: false,
                    },
                    axisLine: {
                        lineStyle: {
                            color: '#D7D7D7',
                        },
                    },
                    splitLine: { show: false },
                },
                yAxis: [
                    {
                        name: '单位(方)',
                        nameTextStyle: {
                            // padding: [0, 0, -10, 50], // 四个数字分别为上右下左与原位置距离
                            padding: [0, 0, 0, 0], // 四个数字分别为上右下左与原位置距离
                            color: '#979EA7',
                            fontSize: '10',
                        },
                        axisLabel: {
                            color: '#666666',
                            fontSize: 10,
                        },
                        axisTick: {
                            show: false,
                        },
                        axisLine: {
                            lineStyle: {
                                color: '#D7D7D7',
                            },
                        },
                        splitLine: {
                            lineStyle: {
                                color: '#D7D7D7',
                                type: 'dashed',
                            },
                        },
                    },
                    {
                        name: '单位(%)',
                        nameTextStyle: {
                            padding: [0, 0, 0, 0], // 四个数字分别为上右下左与原位置距离
                            color: '#979EA7',
                            fontSize: '10',
                        },
                        axisLabel: {
                            color: '#666666',
                            fontSize: 10,
                        },
                        axisTick: {
                            show: false,
                        },
                        axisLine: {
                            lineStyle: {
                                color: '#D7D7D7',
                            },
                        },
                        splitLine: {
                            lineStyle: {
                                color: '#D7D7D7',
                                type: 'dashed',
                            },
                        },
                    },
                ],
                series: [
                    {
                        name: '发货方量',
                        data: this.tableXsts,
                        type: 'bar',
                        barWidth: 30,
                        color: '#44A1F5',
                        label: {
                            show: true,
                            position: 'top',
                            fontFamily: 'QuartzLTStd',
                            padding: 5,
                            color: '#4D7DE0',
                            // backgroundColor: {
                            //     image: addBgImages(1, 'top'),
                            // },
                        },
                        itemStyle: {
                            normal: {
                                color: new this.$echarts.graphic.LinearGradient(0, 1, 0, 0, [{
                                    offset: 0,
                                    color: '#3E78C3', // 0% 处的颜色
                                }, {
                                    offset: 1,
                                    color: '#44A1F5', // 100% 处的颜色
                                }], false),
                            },
                        },
                    },
                    {
                        name: '签收方量',
                        data: this.tableCgs,
                        type: 'bar',
                        barWidth: 30,
                        color: '#F0AF56',
                        label: {
                            show: true,
                            position: 'top',
                            padding: 5,
                            fontFamily: 'QuartzLTStd',
                            color: '#EA8344',
                            // backgroundColor: {
                            //     image: addBgImages(4, 'top'),
                            // },
                        },
                        itemStyle: {
                            normal: {
                                color: new this.$echarts.graphic.LinearGradient(0, 1, 0, 0, [{
                                    offset: 0,
                                    color: '#EA8243', // 0% 处的颜色
                                }, {
                                    offset: 1,
                                    color: '#F0AF56', // 100% 处的颜色
                                }], false),
                            },
                        },
                    },
                    {
                        name: '签收率',
                        type: 'line',
                        yAxisIndex: 1,
                        data: this.qslLineEcharts,
                        label: {
                            fontFamily: 'QuartzLTStd',
                            show: true,
                            position: 'top',
                            fontSize: '14',
                            padding: 5,
                        },
                        itemStyle: {
                            normal: {
                                color: '#73DABE',
                            },
                        },
                    },
                ],
            };
            myChart.setOption(option, true);
        },
        salesanalysisYwy() {
            this.tableData = [];
            this.tableXsts = [];
            this.tableCgs = [];
            this.tableName = [];
            this.$axios
                .get(`/interfaceApi/report/produceanalysis/scfh/bh/month?date=${this.value1}`
                + '&mixstation_code=' + this.selectStation)
                .then(res => {
                    this.tableData = res.list;
                    this.tableData.forEach(item => {
                        this.tableName.push(item.name);
                        this.tableXsts.push(item.fhfl);
                        this.tableCgs.push(item.qsfl);
                        const qsl = item.qsl.slice(0, -1);
                        this.qslLineEcharts.push(qsl);
                    });
                    this.clueStatisticsChildFun();
                })
                .catch(err => {
                    this.$message.error(err.ErrorCode.message);
                });
        },
        getStationApi() {
            const path = '/interfaceApi/report/produceanalysis/mixstation';
            return this.$axios.$get(path, { defEx: true });
        },
    },
};

</script>
<style lang='stylus'>
.productionNum
    height 100%
    padding .2rem
    .clue-statistics-box-child
        background #fff
        height 100%
        >div
            height 100%
        .select-time
            display flex
            align-items center
            padding-bottom .2rem
            p
                color #000000
                font-size .2rem
                margin-right .2rem
            .el-input
                width 1.6rem !important
                input
                    color #000
            .el-date-editor
                margin-right .1rem
        .day-trend-head
            width 100%
            background #0C2C87
            text-align center
            color #fff
            padding .1rem 0;
            display flex
            justify-content space-between
            align-items center
            span
                font-size .2rem
                &:last-child
                    margin-right .2rem
                    cursor pointer
        .clue-statistics-one
            display flex
            flex-direction column
            justify-content space-between
            border 1px solid #D7D7D7
            h1
                color #333333
                font-size .18rem
                font-weight 800
                padding .2rem
                &:before
                    content '`'
                    width .06rem
                    height .2rem
                    background #4D7DE0
                    color #4D7DE0
                    margin-right .1rem
        .clue-statistics-two
            margin-top .1rem
            height calc(100% - 3.93rem)
            .el-table
                width 100%
                // .el-table__body tr td:first-child
                //     border-bottom none
                .el-table__footer-wrapper tbody tr td div
                    color #C9661E !important
                .el-table__header-wrapper thead tr th
                    background #EDF0F5
                    color #022782
                th, td
                        padding .05rem 0
                        text-align center
                        div
                            font-size .16rem
                    .el-table__footer tbody tr
                        td
                            background none
                            div
                                color #823802
                        td:first-child
                            border-top none
                            background #F5F2ED
                        td:nth-child(2)
                            div
                                color #823802 !important
</style>